import React from "react";
import './Dfooter.css';




function Footer (){
    return(
        <div>
       <footer className="mt-5 footer">
				<div className="bg-grey h-theme d-flex flex-row mx-0">
					<p className="mt-3 mx-auto">Copyright @2023.All Right Reserved.</p>
				</div>
			</footer>
        </div>
    )
}
export default Footer;